<template>
  <div>
    <div class="container-fluid">
      <PageTitle :noAdd="false" />

      <template v-if="isList">
        <ul class="nav nav-tabs customtab" role="tablist">
          <li class="nav-item"> <a class="nav-link active" id="main-nav-dynamic" data-toggle="tab" href="#main" role="tab" @click="changeTab('main')"><span
                class="hidden-sm-up"></span> <span class="hidden-xs-down">Our Work</span></a> </li>
          <li class="nav-item"> <a class="nav-link" id="seo-nav-dynamic" data-toggle="tab" href="#seo" role="tab" @click="changeTab('SEO')"><span
                class="hidden-sm-up"></span> <span class="hidden-xs-down">SEO Setting</span></a> </li>
        </ul>
        <div class="tab-content">
          <div class="tab-pane fade show active" role="tabpanel" id="main">
            <div class="card">
              <div class="card-header">
                <h5 class="card-title mb-0">Hero Image</h5>
                <small>Last updated : {{(row.ahi_update_date||"").dates("format")}}</small>
              </div>
              <div class="card-body">
                <div class="row">
                  <div class="col-md-4">
                    <div class="wrap_slider_img">
                      <img
                        :src="uploader((row.ahi_background || '').removeWebp(),'411')"
                        class="img-responsive" />
                      <div class="slider_name">
                        <p># {{row.ahi_title}}</p>
                      </div>
                      <div class="bullet-cta">
                        <a data-toggle="collapse" v-tooltip="'Edit Hero Image'" @click="openHero()"
                          class="bullet_edit finger"><i class="fas fa-pencil-alt"></i></a>

                      </div>
                    </div>
                  </div>
                </div>

                <ValidationObserver v-slot="{ handleSubmit }" ref="VForm">
                  <form v-if="isHero" v-on:submit.prevent="handleSubmit(doSubmitHero)">
                    <div class="row mt-5" id="collapseExample">
                      <div class="col-md-8 mb-3">
                        <div class="row">
                          <div class="col-md-12">
                            <div class="panel_group">
                              <div class="panel_heading">Hero Image Text</div>
                              <div class="panel_body">
                                <div class="row">
                                  <div class="col-md-12">
                                    <div class="form-group">
                                      <label class="control-label">Title <span class="text-danger mr5">*</span></label>
                                      <input v-model="row.ahi_title" type="text" id="firstName" class="form-control"
                                        placeholder="Page Title">
                                      <VValidate name="Title" v-model="row.ahi_title" rules="required" />

                                    </div>
                                  </div>
                                  <div class="col-md-12">
                                    <div class="form-group">
                                      <label class="control-label">Description <span
                                          class="text-danger">*</span></label>
                                      <textarea class="form-control" v-model="row.ahi_description" rows="2"
                                        placeholder="Page Description">

                                      </textarea>
                                    </div>
                                    <VValidate name="Hero Description" v-model="row.ahi_description"
                                      rules="required" />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-4 mb-3">
                        <Uploader v-model="row.ahi_background" type="hero" label="Hero Image" :block="true" />
                        <VValidate name="Hero Image" v-model="row.ahi_background" rules="required" />
                        <div class="form-group mt-3">
                          <label class="control-label">Alt Image <span class="text-danger">*</span></label>
                          <input type="text" v-model="row.ahi_alt" class="form-control" placeholder="Alt Image">
                          <VValidate name="Alt Image Hero" v-model="row.ahi_alt" rules="required" />
                        </div>

                        <button type="submit" class="btn btn-rounded btn-block btn-info mt-5">Submit</button>

                      </div>
                    </div>
                  </form>
                </ValidationObserver>
              </div>
            </div>

            <div class="card">
              <div class="card-header">
                <div class="row">
                  <div class="col-md-9">
                    <h5 class="card-title mb-0">{{pageTitle}}</h5>
                  </div>
                  <div class="col-md-3 text-right">
                    <div class="btn-group" role="group" aria-label="Basic example">
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-body pb-0">
                <div class="row">
                  <div class="col-md-8 col-12">
                    <div class="btn-group col-md-5 col-12 p-1 m-r-10" role="group" aria-label="Button group with nested dropdown">
                      <div class="input-group">
                        <select v-model="filter.category" class="form-control custom-select" @change="doFilter()">
                          <option value="0" selected="selected">-- Filter by Category-- </option>
                          <option v-for="(v,k) in services" :key="k" :value="v.as_id">{{ v.as_title }}</option>
                        </select>
                      </div>
                    </div>
                    <div class="btn-group col-md-3 col-12 p-1 m-r-10" role="group" aria-label="Button group with nested dropdown">
                      <div class="input-group">
                        <select v-model="filter.status" class="form-control custom-select" @change="doFilter()">
                          <option value="0" selected="selected">-- Filter Status -- </option>
                          <option value="Y">Active</option>
                          <option value="N">Inactive </option>
                        </select>
                      </div>
                    </div>
                    <div class="btn-group col-md-3 col-12 p-1 m-r-10" role="group" aria-label="Button group with nested dropdown">
                      <div class="input-group">
                        <select v-model="filter.sort" class="form-control custom-select" @change="doFilter()">
                          <option value="0" selected="selected">-- Sort By --</option>
                          <option value="most">Most Viewed</option>
                          <option value="least">Least Viewed</option>
                          <option value="oldest">Oldest Publish</option>
                          <option value="newest">Newest Publish</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <form @submit.prevent="doFilter(),doSearch()">
                      <div class="form-group mb-0">
                        <div class="input-group p-1">
                          <input v-model="filter.search" type="text" class="form-control"
                            placeholder="Type and Press Enter" @input="checkDisabled" />
                          <div class="input-group-append">
                            <button class="btn btn-info" type="submit" id="btn-search" disabled><i
                                class="fas fa-search"></i></button>
                          </div>
                          <button v-if="isSearch" @click="doReset(),clsSearch()" type="button"
                            class="btn btn-danger ml-1"><i class="fa fa-times"></i>
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div class="card-body">

                <div class="row" v-if="data.data===false">
                  <div class="col-md-12 d-flex justify-content-center">
                    <Loader class="mt10 mb10" />
                  </div>
                </div>
                <div class="row gutter-20" v-if="data.data.length > 0">
                  <div class="col-md-3" v-for="(v,k) in data.data" :key="k">
                    <div class="wrap_article" v-tooltip="v.acs_update_date ? 'Last updated : ' + v.acs_update_date.dates('format2') : 'Created : ' + v.acs_create_date.dates('format2')">
                      <div class="wrap_thumb">
                        <img :src="uploader((v.acs_img || '').removeWebp())" class="img-responsive" />
                        <div class="bullet-cta">
                          <router-link :to="{name:$route.name, params:{pageSlug:v[idKey]}}" class="bullet_edit mr-2" v-tooltip="'Edit'">
                            <i class="fas fa-pencil-alt"></i>
                          </router-link>
                          <a v-tooltip="'Change Status'" @click.prevent="doChangeStatus(k,v)"
                            class="bullet_edit finger mr-2"><i class="fa fa-cog"></i></a>
                          <a v-tooltip="'Delete'" @click.prevent="doDelete(v.acs_id, v)" class="bullet_delete" style="cursor: pointer;"><i
                              class="far fa-trash-alt"></i></a>
                        </div>
                      </div>
                      <div class="wrap_content_post text-center">
                        <span class="label"
                          :class="v.acs_is_active === 'Y' ? 'label-success' : 'label-danger'">{{ v.acs_is_active === "Y" ? "Active" : "Inactive" }}</span>
                        <h6>
                          {{v.as_title}}
                        </h6>
                        <h2 v-tooltip="v.acs_title.limitChar(50).endsWith('...') ? v.acs_title : ''">{{v.acs_title.limitChar(50)}}</h2>
                        <ul class="text-center d-flex flex-column">
                          <li :class="v.acs_publish_date ? 'pb-0' : ''">
                            {{ (v.acs_publish_date || '').dates('format4') }}</li>
                          <li>{{ v.acs_author }}</li>
                        </ul>
                      </div>
                    </div>
                  </div>

                </div>

                <div class="row" v-if="data.data.length <= 0">
                  <div class="col-md-12 d-flex justify-content-center">
                    <h4>{{ isFilter || isSearch ? 'Our Work Not Found' : 'Our Work Not Available' }}</h4>
                  </div>
                </div>
              </div>
              <div class="card-footer bg_gray" v-if="data.last_page > 1">
                <div class="row">
                  <div class="col-md-12">
                    <div class="pagination-flat float-right">
                      <Pagination :data="data" :limit="2" @pagination-change-page="doPaginate">
                        <span slot="prev-nav"><i class="icon-arrow-left"></i></span>
                        <span slot="next-nav"><i class="icon-arrow-right"></i></span>
                      </Pagination>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="card">
              <div class="card-header">
                <h5 class="card-title">Call to action section</h5>
                <small>Last Updated : {{(sectionUpdateDate||"").dates('format2')}}</small>
              </div>
              <ValidationObserver v-slot="{ handleSubmit }" ref="VFormSection">
                <form @submit.prevent="handleSubmit(doSubmitSection)">
                  <div class="card-body">
                    <div class="row">
                      <!-- Column -->
                      <div class="col-lg-6 col-md-6">
                        <div class="row">
                          <div class="col-md-12">
                            <div class="form-group">
                              <label class="control-label">Title <span class="text-danger">*</span></label>
                              <input type="text" class="form-control" v-model="section.title"
                                placeholder="Title" />

                              <VValidate name="Section Title" v-model="section.title" rules="required" />
                            </div>
                          </div>
                          <div class="col-md-12">
                            <div class="form-group">
                              <label class="control-label">Button Label <span class="text-danger">*</span></label>
                              <input type="text" class="form-control" v-model="section.button_label"
                                placeholder="Contact Us Now" />

                              <VValidate name="Button Label" v-model="section.button_label" rules="required" />
                            </div>
                          </div>
                          <div class="col-md-12">
                            <div class="form-group">
                              <label class="control-label">Button Link <span class="text-danger">*</span></label>
                              <input type="text" class="form-control" v-model="section.button_link"
                                placeholder="/contact" />

                              <VValidate name="Button Link" v-model="section.button_link" rules="required" />
                            </div>
                          </div>
                        </div>
                        
                      </div>
                      <div class="col-md-6">
                        <div class="col-md-12">
                          <div class="form-group">
                            <label class="control-label">Description <span class="text-danger">*</span></label>
                            <div class="input-group">
                              <textarea  type="text" rows="10" class="form-control"
                                placeholder="Description" v-model="section.desc"></textarea>
                            </div>
                            <VValidate name="Section Description" v-model="section.desc" rules="required" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="card-footer">
                    <div class="form-actions text-right">
                      <button type="submit" class="btn btn-rounded btn-info">Submit</button>
                    </div>
                  </div>
                </form>
              </ValidationObserver>
            </div>
          </div>
          <div class="tab-pane fade" role="tabpanel" id="seo">
            <div class="card">
              <template v-if="isMeta">
                <div class="card-body">
                  <div class="row">
                    <div class="col-md-12 text-right">
                      <label class="text-primary">Last updated : {{(seoStatic.sss_update_date||"").dates('format2')}}</label>
                    </div>
                    <div class="col-md-12">
                      <div class="form-group">
                        <label class="control-label">Meta Title <a
                            v-tooltip="'This field functions to ease Google to understand the title of this website page. Tips: Make sure the characters length is less than 70 chars.'"
                            class="help_info">?</a> <span class="text-danger mr5">*</span></label>
                            <p>{{seoStatic.sss_title}}</p>
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="" class="control-label">
                          Meta Keyword
                          <a class="help_info"
                            v-tooltip="'It can be used to give more information to search engines on what a page is about. The keywords are generally written in lowercase and separated with a comma.'">
                            ?
                          </a>
                          <span class="text-danger mr5">*</span>
                        </label>
                        <div></div>
                        <template v-for="(v,k) in seoStatic.sss_keyword" >
                          <label class="label label-success mr-1" :key="k">
                            {{v}}
                          </label>
                        </template>
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="form-group">
                        <label class="control-label">Meta Description <a
                            v-tooltip="'A meta description is an element that contains a short summary of the page and generates the brief snippet we see underneath a site`s title in Google`s search results.'"
                            class="help_info">?</a> <span class="text-danger mr5">*</span></label>
                        <p>{{seoStatic.sss_desc}}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card-footer">
                  <div class="form-actions text-right">
                    <a href="javascript:;" @click="editMeta()" class="btn btn-rounded btn-info">Edit</a>
                  </div>
                </div>
              </template>
              <ValidationObserver v-slot="{ handleSubmit }" ref="VForm" v-else>
                <div class="card-body pb-0">
                  <div class="form-group row align-items-center mb-0">
                    <label for="" class="col-form-label col-auto">Auto-fill SEO?</label>
                    <div class="col-md">
                      <div class="ia-switch" @click.stop="editAutoFill">
                        <input type="checkbox" v-model="isCheck" name="toggleAutoSeo" id="toggleAutoSeo" class="ia-switch-input">
                        <label for="toggleAutoSeo" class="ia-input-label"></label>
                      </div>
                    </div>
                  </div>
                </div>
                <form @submit.prevent="handleSubmit(doSubmitMeta)">
                  <div class="card-body">
                    <div class="row">
                      <div class="col-md-12">
                        <div class="form-group">
                          <label class="control-label">Meta Title <a
                              v-tooltip="'This field functions to ease Google to understand the title of this website page. Tips: Make sure the characters length is less than 70 chars.'"
                              class="help_info">?</a> <span class="text-danger mr5">*</span></label>
                          <input required="" type="text" v-model="seoStatic.sss_title" class="form-control"
                            placeholder="Meta Title" @input="checkDisabledSeo">
                          <VValidate name="Meta Title" :rules="{required: 1}" />
                        </div>

                      </div>
                      <div class="col-md-12">
                        <div class="form-group">
                          <label for="" class="control-label">
                            Meta Keyword
                            <a class="help_info"
                              v-tooltip="'It can be used to give more information to search engines on what a page is about. The keywords are generally written in lowercase and separated with a comma.'">
                              ?
                            </a>
                            <span class="text-danger mr5">*</span>
                          </label>
                          <input-tag v-model="seoStatic.sss_keyword" @input="checkDisabledSeo"></input-tag>
                          <VValidate name="Keyword" rules="required" />
                        </div>
                      </div>
                      <div class="col-md-12">
                        <div class="form-group">
                          <label class="control-label">Meta Description <a
                              v-tooltip="'A meta description is an element that contains a short summary of the page and generates the brief snippet we see underneath a site`s title in Google`s search results.'"
                              class="help_info">?</a> <span class="text-danger mr5">*</span></label>
                          <textarea required v-model="seoStatic.sss_desc" class="form-control" rows="4"
                            @input="checkDisabledSeo"></textarea>

                          <VValidate name="Meta Description" :rules="{required: 1}" />
                        </div>
                      </div>
                    </div>
                  </div>
                  
                  <div class="card-footer">
                    <div class="form-actions text-right">
                      <button @click="reset" type="button" class="btn  btn-rounded  btn-secondary" id="button-cancel"
                        disabled>Cancel
                      </button>
                      <button type="submit" class="btn  btn-rounded btn-info">Submit</button>
                    </div>
                  </div>

                </form>
              </ValidationObserver>
            </div>
          </div>
        </div>

      </template>
      <div v-else>
        <div class="row" style="overflow: auto;">
          <div class="col-lg-12">
            <ValidationObserver v-slot="{ handleSubmit }" ref="VForm">
              <form @submit.prevent="handleSubmit(doSubmitCRUD,clsMeta)">
                <div class="card">
                  <div class="row">
                    <div class="col-md-12">
                      <ul class="nav nav-tabs customtab" role="tablist">
                        <li class="nav-item"> <a class="nav-link active" id="main-nav" @click="our;changeTab('mainForm')" data-toggle="tab"
                            href="#editor" role="tab"><span class="hidden-sm-up"></span> <span
                              class="hidden-xs-down">Our Work</span></a>
                        </li>
                        <li class="nav-item"> <a class="nav-link" id="seo-nav" @click="seo;changeTab('SEOForm')" data-toggle="tab"
                            href="#seo" role="tab"><span class="hidden-sm-up"></span> <span class="hidden-xs-down">SEO
                              Setting</span></a> </li>
                      </ul>
                      <div class="tab-content">
                        <div class="tab-pane active" id="editor" role="tabpanel">
                          <div class="card-body">
                            <div class="form-body">
                              <div class="row">
                                <div class="col-md-8 mt-3">
                                  <div class="row">
                                    <div class="col-md-7">
                                      <div class="form-group">
                                        <label class="control-label"> Category <span
                                            class="text-danger mr5">*</span></label>
                                          <!--
                                          <div class="input-group">
                                          <select class="form-control custom-select select-service" placeholder="-- Select One --"
                                            style="width: 100%; height:36px;" v-model="row.acs_service">
                                            <option :value="0" disabled selected="selected">-- Select One-- </option>
                                            <option :value="v.as_id" v-for="(v,k) in services" :key="k">
                                              {{ v.as_title }}</option>
                                          </select>
                                          </div>
                                          -->

                                          <vSelect v-model="row.acs_service" :options="services" :reduce="v => v.as_id"
                                            placeholder="Select One" label="as_title"></vSelect>
                                          <VValidate name="Service Category" v-model="row.acs_service" rules="required"/>
                                      </div>
                                    </div>
                                    <div class="col-md-11">
                                      <div class="form-group">
                                        <label class="control-label">Title <span
                                            class="text-danger mr5">*</span></label>
                                        <input type="text" v-model="row.acs_title" class="form-control"
                                          placeholder="Title">
                                        <VValidate name="Title" v-model="row.acs_title"
                                          :rules="mrValidation.acs_title" />
                                      </div>
                                    </div>
                                    
                                    <!--
                                    <div class="col-md-11">
                                      <div class="form-group">
                                        <label class="control-label">Slug<span class="text-danger mr5">*</span></label>

                                        <div class="input-group">
                                          <input v-model="row.acs_slug" type="text" id="acs_slug" class="form-control"
                                            placeholder="Slug" :readonly="isReadonly">
                                          <div v-on:click="doSlug()" class="input-group-append finger">
                                            <button type="button" class="btn btn-secondary"><i class="icon-pencil"></i></button>
                                          </div>
                                        </div>
                                        <VValidate name="Slug" v-model="row.acs_slug"
                                          :rules="{required:1, regex:/^[^\s]+$/ }" />
                                      </div>
                                    </div>
                                    -->

                                    <div class="col-md-11">
                                      <div class="form-group">
                                        <label class="control-label">Content Description <span
                                            class="text-danger mr5">*</span></label>
                                        <CKEditor 
                                          :value.sync="row.acs_desc"
                                        />

                                        <VValidate name="Desc" v-model="row.acs_desc" :rules="mrValidation.acs_desc" />
                                      </div>
                                    </div>

                                    <div class="col-md-11">
                                      <div class="form-group">
                                        <label class="control-label">Tags <span class="text-danger mr5">*</span></label>
                                        <input-tag placeholder="Type and click enter to add it" v-model="row.acs_tags"></input-tag>
                                        <VValidate name="Tags" v-model="row.acs_tags" :rules="mrValidation.acs_tags" />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-md-4">
                                  <div class="row">
                                    <div class="col-md-12">
                                      <label class="control-label">Main Image <span
                                          class="text-danger mr5">*</span></label>
                                      <Uploader v-model="row.acs_img" type="ourwork" label="Photo" />
                                      <VValidate name="Image" v-model="row.acs_img" :rules="mrValidation.acs_img" />

                                      <div class="form-group mt-3">
                                        <label class="control-label">Alt Image <span
                                            class="text-danger">*</span></label>
                                        <input type="text" v-model="row.acs_alt" class="form-control"
                                          placeholder="Alt Image">
                                        <VValidate name="Alt Image" v-model="row.acs_alt" rules="required" />
                                      </div>
                                    </div>

                                  </div>

                                  <div class="row mt-3">
                                    <div class="col-md-12">
                                      <div class="form-group">
                                        <label class="control-label">Author <span
                                            class="text-danger mr5">*</span></label>
                                        <input type="text" v-model="row.acs_author" class="form-control"
                                          placeholder="Author">

                                        <VValidate name="Author" v-model="row.acs_author"
                                          :rules="mrValidation.acs_author" />
                                      </div>
                                    </div>

                                    <div class="col-md-12">
                                      <div class="form-group">
                                        <label class="control-label">Publish Date <span
                                            class="text-danger mr5">*</span></label>
                                        <datepicker input-class="inp-date col-md-12" placeholder="Select Date"
                                          class="my-datepicker" calendar-class="my-datepicker_calendar"
                                          v-model="row.acs_publish_date">
                                        </datepicker>
                                        <span class="ic_apeend"><i class="icon-calender"></i></span>
                                      </div>
                                      <VValidate name="Publish Date" v-model="row.acs_publish_date" rules="required" />
                                    </div>
                                    <div class="col-sm-12">
                                      <label class="control-label">Status <span class="text-danger mr5">*</span>
                                      </label>
                                      <div>
                                        <InputRadio v-model="row[statusKey]" name="status" option="Y" />
                                        <InputRadio v-model="row[statusKey]" name="status" option="N" />
                                      </div>
                                      <VValidate name="Status" v-model="row[statusKey]"
                                        :rules="mrValidation[statusKey]" />
                                    </div>
                                  </div>
                                </div>

                              </div>

                            </div>
                          </div>
                        </div>
                        <div class="tab-pane" id="seo" role="tabpanel">
                          <div v-if="!isMeta" class="card-body">
                            <div class="row" style="width: 100%">
                              <div class="col-md-12">
                                <div class="form-group">
                                  <label class="control-label">Meta Title
                                    <a class="help_info"
                                      v-tooltip="'This field functions to ease Google to understand the title of this website page. Tips: Make sure the characters length is less than 70 chars.'">?</a>
                                    <span class="text-danger mr5">*</span>
                                  </label>
                                  <input v-model="row.acs_meta_title" type="text" class="form-control"
                                    placeholder="Meta Title">


                                  <VValidate name="Meta Title" v-model="row.acs_meta_title"
                                    :rules="{required: mrValidation.acs_meta_title}" />
                                </div>
                              </div>
                              <div class="col-md-12">
                                <div class="form-group">
                                  <label class="control-label">Meta Keyword
                                    <a class="help_info"
                                      v-tooltip="'It can be used to give more information to search engines on what a page is about. The keywords are generally written in lowercase and separated with a comma.'">
                                      ?</a> <span class="text-danger mr5">*</span></label>
                                  <input-tag v-model="row.acs_meta_keyword"></input-tag>
                                  <VValidate name="Meta Keyword" v-model="row.acs_meta_keyword"
                                    :rules="mrValidation.acs_meta_keyword" />
                                </div>
                              </div>
                              <div class="col-md-12">
                                <div class="form-group">
                                  <label class="control-label">Meta Description
                                    <a class="help_info"
                                      v-tooltip="'A meta description is an element that contains a short summary of the page and generates the brief snippet we see underneath a site`s title in Google`s search results.'">
                                      ?</a> <span class="text-danger mr5">*</span></label>
                                  <textarea v-model="row.acs_meta_description" class="form-control"
                                    placeholder="Meta Description"
                                    rows="4">Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. but the majority have suffered alteration in some form, by injected humour</textarea>
                                  <VValidate name="Meta Description" v-model="row.acs_meta_description"
                                    :rules="{required: mrValidation.acs_meta_description}" />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="card-body" v-else>
                            <div class="row">
                              <div class="col-md-12">
                                <div class="form-group">
                                  <label class="control-label">Meta Title <a
                                      v-tooltip="'This field functions to ease Google to understand the title of this website page. Tips: Make sure the characters length is less than 70 chars.'"
                                      class="help_info">?</a> <span class="text-danger mr5">*</span></label>
                                      <p>{{row.acs_meta_title}}</p>
                                </div>
                              </div>
                              <div class="col-md-12">
                                <div class="form-group">
                                  <label for="" class="control-label">
                                    Meta Keyword
                                    <a class="help_info"
                                      v-tooltip="'It can be used to give more information to search engines on what a page is about. The keywords are generally written in lowercase and separated with a comma.'">
                                      ?
                                    </a>
                                    <span class="text-danger mr5">*</span>
                                  </label>
                                  <div></div>
                                  <template v-for="(v,k) in row.acs_meta_keyword" >
                                    <label class="label label-success mr-1" :key="k">
                                      {{v}}
                                    </label>
                                  </template>
                                </div>
                              </div>
                              <div class="col-md-12">
                                <div class="form-group">
                                  <label class="control-label">Meta Description <a
                                      v-tooltip="'A meta description is an element that contains a short summary of the page and generates the brief snippet we see underneath a site`s title in Google`s search results.'"
                                      class="help_info">?</a> <span class="text-danger mr5">*</span></label>
                                  <p>{{row.acs_meta_description}}</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="card-footer">
                    <div class="form-actions text-right" v-if="tab == 'SEOForm'">
                      <button type="button" @click="cancelButton()" class="btn mr-2 btn-rounded  btn-secondary">Cancel</button>
                      <template v-if="!isMeta">
                        <button type="submit" @click="doValidate()" class="btn  btn-rounded  btn-info">Submit</button>
                      </template>
                      <template v-else>
                        <a href="javascript:;" @click="editMeta()" class="btn btn-rounded btn-info">Edit</a>
                      </template>
                    </div>
                    <div class="form-actions text-right" v-else>
                      <button type="button" @click="cancelButton()" class="btn mr-2 btn-rounded  btn-secondary">Cancel</button>
                      <button type="submit" @click="doValidate()" class="btn  btn-rounded  btn-info">Submit</button>                      
                    </div>
                  </div>
                </div>
              </form>
            </ValidationObserver>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import GlobalVue from '@libs/Global'
  import PageTitle from '@view/include/PageTitle'
  import Gen from '@libs/Gen.js'
  import Datepicker from 'vuejs-datepicker'
  import CKEditor from '@/components/CKEditor'

  let $ = global.jQuery
  let _ = global._

  export default {
    extends: GlobalVue,
    components: {
      PageTitle,
      Datepicker,
      CKEditor
    },
    data() {
      return {
        idKey: 'acs_id',
        isSearch: false,
        statusKey: 'acs_is_active',
        services: [],
        seoSettings: false,
        isReadonly: true,
        seoStatic: {},
        isHero: false,
        isMeta: true,
        isCheck: false,
        isActiveServ : false,
        section: {},
        row: {},
        TabSeo : JSON.parse(localStorage.getItem("SEO")),
        tab: "main",
        metaLatest : {},
        sectionUpdateDate : ""
      }
    },
    mounted() {
      this.apiGet()
      this.apiGetHeroImage()
      this.seoSettings = false
      this.filter["category"] = 0
      this.filter["status"] = 0
      this.filter["sort"] = 0

      if (this.isAdd) {
        this.isMeta = false
      }

      if(this.TabSeo !== null && this.TabSeo.menu === this.$route.name && this.TabSeo.val === "Y"){
        this.tab = 'SEO'
        
        $("#main").removeClass("active").removeClass("show");
        $("#seo").addClass("active").addClass("show");

        $("#main-nav-dynamic").removeClass("active");
        $("#seo-nav-dynamic").addClass("active");
        
      }

      if(this.TabSeo !== null && this.TabSeo.menu === this.$route.name && this.TabSeo.valChild === "Y"){
        this.tab = 'SEOForm'
        
        $("#editor").removeClass("active").removeClass("show");
        $("#seo").addClass("active").addClass("show");

        $("#main-nav").removeClass("active");
        $("#seo-nav").addClass("active");
        
      }
      else{
        this.tab = 'main'
      }

      if(this.isList){
        if(this.$route.query.search){
          if (this.filter.search.length <= 0) {
            this.isSearch = false
            $('#btn-search').attr("disabled", "disabled")
          } else {
            this.isSearch = true
            $('#btn-search').removeAttr("disabled")
          }  
        }
        let confSeo = JSON.stringify({ menu : this.$route.name, val : "N"})
        localStorage.setItem("SEO", confSeo)
      }

    },
    methods: {
      changeTab(tab){
        this.tab = tab
        
        if(this.tab === "SEO"){
          let confSeo = JSON.stringify({ menu : `${this.$route.name}`, val : "Y"})
          localStorage.setItem("SEO", confSeo)
        }
        else if(this.tab === "SEOForm"){
          let confSeo = JSON.stringify({ menu : `${this.$route.name}`, val : "N", valChild: "Y"})
          localStorage.setItem("SEO", confSeo)
        }
        else{
          let confSeo = JSON.stringify({ menu : `${this.$route.name}`, val : "N"})
          localStorage.setItem("SEO", confSeo)
        }

      },
      doChangeStatus(k,v){
        let id = v[this.idKey]
        let changeStatus = v[this.statusKey] == "Y" ? "N" : "Y"
        global.Swal.fire({
          title: `Change status to ${changeStatus=='N' ? 'Inactive' : 'Active'}?`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: `Yes, change to ${changeStatus=='N'? 'Inactive' : 'Active'}!`,
          customClass: {
            confirmButton: 'btn btn-custom',
            cancelButton: 'btn btn-outline-custom ml-1',
          },
          buttonsStyling: false, 
        }).then((result) => {
          if (result.value) {
            this.loadingOverlay=true

            Gen.apiRest(
              "/do/"+this.modulePage,
              {data:{type:'status',id:id,status:changeStatus}}, 
              "POST"
            )
              .then(()=>{
                this.loadingOverlay = false 
                v[this.statusKey] = changeStatus

                global.Swal.fire({
                  title: `Status changed to ${changeStatus=='Y'? 'Active' : 'Inactive'}.`,
                  icon: 'success',
                })
              })
              .catch((err)=>{ 
                this.loadingOverlay = false 
                if(err){
                    err.message = err.response.data.message;
                    return global.Swal.fire({
                      title: err.message,
                      text: 'The service status that related to this work is inactive. Change the service status first to activate this work',
                      icon: 'error',
                      confirmButtonText: 'Ok'
                    })
                }
              })
          }
        })
      },
      cancelButton(){
        global.Swal.fire({
          icon: 'warning',
          title: 'Are You Sure to Cancel?',
          text: 'You will not able to recover this data.',
          showCancelButton: true,
          confirmButtonText: `Yes, Cancel`,
          cancelButtonText: 'No',
          customClass: {
            confirmButton: 'btn btn-custom',
            cancelButton: 'btn btn-outline-custom ml-1',
          },
        buttonsStyling: false,
        }).then(res => {  
          if (res.value) {
            let confSeo = JSON.stringify({ menu : this.$route.name, val : "N"})
            localStorage.setItem("SEO", confSeo)
            this.$router.push({
              name: this.$route.name
            })
          }
        })
      },
      clsMeta(){
        this.isMeta = true  
      },
      editMeta(){
        this.isMeta = false
        this.isCheck = false
      },
      editAutoFill(){
        if(!this.isCheck){
          global.Swal.fire({
            icon: 'warning',
            title: 'Change back SEO Content to Auto-fill Mode?',
            text: 'Any changes you made will be lost!',
            showCancelButton: true,
            confirmButtonText: `Yes, change it!`,
            cancelButtonText: 'No Cancel',
            customClass: {
              confirmButton: 'btn btn-custom',
              cancelButton: 'btn btn-outline-custom ml-1',
            },
            buttonsStyling: false, 
          }).then(res => {      
            if (res.value) {
              this.isCheck = true
              this.seoStatic = {
                sss_id : this.seoStatic.sss_id,
                sss_title : this.metaLatest.acs_meta_title,
                sss_desc : this.metaLatest.acs_meta_description,
                sss_keyword : this.metaLatest.acs_meta_keyword
              }
              $('#button-cancel').removeAttr('disabled')
            }
            else{
              this.isCheck = false
            }
          })
        }
        else{
          this.apiGet()
        }
      },
      apiGet(params = {}){
        if(this.pageSlug) this.loadingOverlay = true
        this.data.data = false
        Gen.apiRest(
          "/get/"+this.modulePage+
          (this.pageSlug?"/"+this.pageSlug:"")+
          (this.pageId?"/"+this.pageId:""), 
          {
            params: Object.assign({}, this.apiParams, params.query||{})
          }
        ).then(res=>{
          this.loadingOverlay = false
          _.forEach(res.data, (v,k)=>{
            this.$set(this, k, v)
          })

          if(this.row.acs_service && this.pageSlug !== "add"){
            setTimeout(()=>{
              this.row.acs_service = +this.row.acs_service
            },0)
          }

          if(this.row.acs_service == ''){
            delete this.row.acs_service
          }

          this.metaLatest = res.data.metaLatest
          this.sectionUpdateDate = res.data.section.sw_update_date           
          this.setSection()

          if(!this.isList){
            this.setFocusForm()
          }
          if(params.callback) params.callback()
        }).catch(()=>{
          this.loadingOverlay = false
        })
      },
      doSearch() {
        this.isSearch = true
      },
      doSlug() {
        if (this.isReadonly) {
          this.isReadonly = false
        } else {
          this.isReadonly = true
        }
      },
      our() {
        this.seoSettings = false
      },
      seo() {
        this.seoSettings = true
      },
      next() {
        this.seoSettings = true
      },
      clsSearch() {
        this.isSearch = false
        setTimeout(() => {
          $('#btn-search').attr("disabled", "disabled")
        }, 0)
      },
      splitTitle(content, len) {
        const countTitle = content.split(' ')
        const ctLen = countTitle.length
        if (ctLen > len) countTitle.splice(len)
        return `${countTitle.join(' ')} ${content.split(' ').length > len ? '...' : ''}`
      },
      apiGetHeroImage: function () {
        if (this.pageSlug) this.loadingOverlay = true

        this.data.data = false
        Gen.apiRest(
          "/get/" + "HeroImageOurWork"
        ).
        then(res => {
          this.loadingOverlay = false

          setTimeout(() => {
            this.row = Object.assign({}, this.row, res.data.row)
          }, 300)

        })
        .catch(() => {
          this.loadingOverlay = false
        })
      },
      doSubmitHero() {
        this.row.ahi_update_by = this.user.id;
        return this.doSubmit(
          "/do/" + this.modulePage,
          _.assign({
            type: 'updateHeroImage',
            id: this.row.ahi_id
          }, this.row),
          (type, resp) => {
            if (type == 'success') {
              this.isHero = false
              this.row = resp.data
              window.scrollTo(0, 0)
              $('#collapseExample').removeClass('show')
            } else {
              if (resp.response.status == 422) {
                if ((_.values(resp.response.data)[0][0] || "").indexOf('validation') > -1) {
                  var msg = ""
                  $.each(Object.keys(resp.response.data), (i, v) => {
                    msg = msg + " <br/>" + v + " : " + _.values(resp.response.data[v]);
                  })
                  return global.Swal.fire("Blocked With Validation!", msg)
                } else {
                  return global.Swal.fire("Sorry, There is something wrong!", _.values(resp.response.data)[0][0])
                }
              } else if (resp.response.status == 400) {
                return global.Swal.fire({
                  title: resp.response.data.message,
                  icon: 'error',
                  confirmButtonText: 'Ok'
                })
              }
            }
          },
        )
      },
      
      doBackSuccessMeta(){
        this.tab = "main"
        $("#seo").removeClass("active").removeClass("show");
        $("#main").addClass("active").addClass("show");

        $("#seo-nav-dynamic").removeClass("active");
        $("#main-nav-dynamic").addClass("active");

        let confSeo = JSON.stringify({ menu : this.$route.name, val : "N"})
        localStorage.setItem("SEO", confSeo)
      },

      doSubmitMeta() {
        return this.doSubmit(
          "/do/" + this.modulePage,
          _.assign({
            type: 'updateMeta',
            id: this.seoStatic.sss_id
          }, this.seoStatic),
          (type, resp) => {
            if (type == 'success') {
              this.seoStatic = resp.data
              this.isMeta = true
              this.doBackSuccessMeta()
            } else {
              if (resp.response.status == 422) {
                if ((_.values(resp.response.data)[0][0] || "").indexOf('validation') > -1) {
                  var msg = ""
                  $.each(Object.keys(resp.response.data), (i, v) => {
                    msg = msg + " <br/>" + v + " : " + _.values(resp.response.data[v]);
                  })
                  return global.Swal.fire("Blocked With Validation!", msg)
                } else {
                  return global.Swal.fire("Sorry, There is something wrong!", _.values(resp.response.data)[0][0])
                }
              } else if (resp.response.status == 400) {
                return global.Swal.fire({
                  title: resp.response.data.message,
                  icon: 'error',
                  confirmButtonText: 'Ok'
                })
              }
            }
          },
        )
      },
      doSubmitSection() {
        var self = this
        return this.doSubmit(
          "/do/" + this.modulePage,
          _.assign({
            type: 'updateSection',
          }, this.section),
          (type, resp) => {
            if (type == 'success') {
              $(".swal2-confirm").click(function() {
                setTimeout(()=>{
                  document.body.scrollTop = document.documentElement.scrollTop = 0
                  self.apiGet()
                },500)
              })
            } else {
              if (resp.response.status == 422) {
                if ((_.values(resp.response.data)[0][0] || "").indexOf('validation') > -1) {
                  var msg = ""
                  $.each(Object.keys(resp.response.data), (i, v) => {
                    msg = msg + " <br/>" + v + " : " + _.values(resp.response.data[v]);
                  })
                  return global.Swal.fire("Blocked With Validation!", msg)
                } else {
                  return global.Swal.fire("Sorry, There is something wrong!", _.values(resp.response.data)[0][0])
                }
              } else if (resp.response.status == 400) {
                return global.Swal.fire({
                  title: resp.response.data.message,
                  icon: 'error',
                  confirmButtonText: 'Ok'
                })
              }
            }
          },
          'POST', 'VFormSection'
        )
      },
      doValidate() {
        // Validate SEO
        if(this.tab == "SEOForm"){
          if (!this.row.acs_title || !this.row.acs_is_active  || !this.row.acs_tags || !this
            .row.acs_desc || !this.row.acs_author || !this.row.acs_img || !this.row.acs_alt || !this.row.acs_service || !this.row.acs_publish_date) {
            $("#editor").addClass("active");
            $("#seo").removeClass("active");

            $("#main-nav").addClass("active");
            $("#seo-nav").removeClass("active");

            this.seoSettings = false
          }
        }
        // Validate Form
        else{
          if (this.row.acs_meta_title.length < 1 || this.row.acs_meta_description.length < 1 || !this.row.acs_meta_title || !this.row.acs_meta_description || !this.row.acs_meta_keyword) {
            $("#editor").removeClass("active");
            $("#seo").addClass("active");

            $("#main-nav").removeClass("active");
            $("#seo-nav").addClass("active");

            this.seoSettings = true
          } 
        }
      },
      reset() {
        this.apiGet()
        this.apiGetHeroImage()
        this.isCheck = false
        $('#button-cancel').attr('disabled', 'disabled')
      },
      openHero() {
        this.isHero = !this.isHero;
      },
      checkDisabledSeo() {
        $('#button-cancel').removeAttr('disabled')
      },
      checkDisabled() {
        if (this.filter.search.length <= 0) {
          $('#btn-search').attr("disabled", "disabled")
        } else {
          $('#btn-search').removeAttr("disabled")
        }
      },
      cleanMultipleDash(string){
        string = string.replace(/-{2,}/g, '-');
        return string;
      },
      checkToggleAuto(e) {
        if (this.isMeta) {
          e.preventDefault()
        }
      },
      setSection(){
        const sectionVal = JSON.parse(this.section.sw_description)
        this.section = {
          title: this.section.sw_title,
          desc: sectionVal.desc,
          button_label: sectionVal.button_label,
          button_link: sectionVal.button_link
        }
      }
    },
    watch: {
      $route() {
        this.apiGet()
        this.apiGetHeroImage()
        this.seoSettings = false

        if (this.isAdd) {
          this.isMeta = false   
        }

        this.tab = 'main'

        if (this.isList){
          let confSeo = JSON.stringify({ menu : this.$route.name, val : "N"})
          localStorage.setItem("SEO", confSeo)
          this.isMeta = true          
        }

        if (!this.isList) {
          this.isSearch = false
          this.filter = {
            category: 0,
            status: 0
          }
        } else {
          this.filter = {
            category: 0,
            status: 0,
            ...this.filter
          }
        }

      },
      'row.acs_title'(v) {
        this.titleLength = v.length
        if (this.isAdd) {
          // this.row.acs_slug = this.cleanMultipleDash(v.toLowerCase().trim().replace(/ /g, '-').replace(/[^\w-]+/g, ''))
          this.row.acs_meta_title = v
          this.row.acs_alt = v
        } else {
          // this.row.acs_slug = this.cleanMultipleDash(v.toLowerCase().trim().replace(/ /g, '-').replace(/[^\w-]+/g, ''))
          this.row.acs_alt = v
        }
      },
      'row.acs_tags'(v) {
        if(this.isAdd){
          this.row.acs_meta_keyword = v
        }
      },
      'row.acs_desc'(v){
        if(this.isAdd && this.$route.params.pageSlug){
          this.row.acs_meta_description = v.replace(/<[^>]*>?/gm, '')
        }
      },
      // 'row.acs_slug'(v) {
      //   this.row.acs_slug = this.cleanMultipleDash(v.toLowerCase().trim().replace(/ /g, '-').replace(/[^\w-]+/g, ''))
      // },
      'row.ahi_title'(v){
        this.row.ahi_alt = v
      },
    }
  }
</script>
